import * as i0 from '@angular/core';
import { Injectable, Directive, Input, NgModule } from '@angular/core';
class AmChartsService {
  constructor(zone) {
    this.zone = zone;
  }
  get StockPanel() {
    return AmCharts.StockPanel;
  }
  get StockGraph() {
    return AmCharts.StockGraph;
  }
  get StockEvent() {
    return AmCharts.StockEvent;
  }
  get StockLegend() {
    return AmCharts.StockLegend;
  }
  get baseHref() {
    return AmCharts.baseHref;
  }
  set baseHref(v) {
    AmCharts.baseHref = v;
  }
  get useUTC() {
    return AmCharts.useUTC;
  }
  set useUTC(v) {
    AmCharts.useUTC = v;
  }
  get dayNames() {
    return AmCharts.dayNames;
  }
  set dayNames(v) {
    AmCharts.dayNames = v;
  }
  get monthNames() {
    return AmCharts.monthNames;
  }
  set monthNames(v) {
    AmCharts.monthNames = v;
  }
  get shortDayNames() {
    return AmCharts.shortDayNames;
  }
  set shortDayNames(v) {
    AmCharts.shortDayNames = v;
  }
  get shortMonthNames() {
    return AmCharts.shortMonthNames;
  }
  set shortMonthNames(v) {
    AmCharts.shortMonthNames = v;
  }
  // TODO better type for this
  get theme() {
    return AmCharts.theme;
  }
  // TODO better type for this
  set theme(v) {
    AmCharts.theme = v;
  }
  get processDelay() {
    return AmCharts.processDelay;
  }
  set processDelay(v) {
    AmCharts.processDelay = v;
  }
  get charts() {
    return AmCharts.charts;
  }
  addInitHandler(handler, types) {
    // TODO use this.zone.runOutsideAngular ?
    AmCharts.addInitHandler(handler, types);
  }
  addPrefix(value, prefixesBig, prefixesSmall, numberFormatter) {
    // TODO use this.zone.runOutsideAngular ?
    return AmCharts.addPrefix(value, prefixesBig, prefixesSmall, numberFormatter);
  }
  clear() {
    // TODO use this.zone.runOutsideAngular ?
    AmCharts.clear();
  }
  formatDate(date, format) {
    // TODO use this.zone.runOutsideAngular ?
    return AmCharts.formatDate(date, format);
  }
  formatNumber(value, formatter, zeroCount) {
    // TODO use this.zone.runOutsideAngular ?
    return AmCharts.formatNumber(value, formatter, zeroCount);
  }
  stringToDate(value, format) {
    // TODO use this.zone.runOutsideAngular ?
    return AmCharts.stringToDate(value, format);
  }
  // TODO is Node the correct type ?
  // TODO better type for config
  makeChart(id, config, delay) {
    return this.zone.runOutsideAngular(() => AmCharts.makeChart(id, config, delay));
  }
  addListener(chart, type, fn) {
    const callback = e => {
      this.zone.run(() => {
        fn(e);
      });
    };
    this.zone.runOutsideAngular(() => {
      chart.addListener(type, callback);
    });
    return () => {
      this.zone.runOutsideAngular(() => {
        chart.removeListener(chart, type, callback);
      });
    };
  }
  updateChart(chart, fn) {
    this.zone.runOutsideAngular(() => {
      fn();
      chart.validateNow(true);
    });
  }
  destroyChart(chart) {
    this.zone.runOutsideAngular(() => {
      chart.clear();
    });
  }
}
AmChartsService.ɵfac = function AmChartsService_Factory(t) {
  return new (t || AmChartsService)(i0.ɵɵinject(i0.NgZone));
};
AmChartsService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AmChartsService,
  factory: AmChartsService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AmChartsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();
function getType(x) {
  // TODO make this faster ?
  return {}.toString.call(x);
}
function hasOwnKey(obj, key) {
  return {}.hasOwnProperty.call(obj, key);
}
function copyObject(x) {
  const output = {};
  // TODO use Object.keys ?
  for (const key in x) {
    if (hasOwnKey(x, key)) {
      output[key] = copy(x[key]);
    }
  }
  return output;
}
function copyArray(x) {
  const length = x.length;
  const output = new Array(length);
  for (let i = 0; i < length; ++i) {
    output[i] = copy(x[i]);
  }
  return output;
}
// TODO can this be made faster ?
// TODO what about regexps, etc. ?
function copy(x) {
  switch (getType(x)) {
    case '[object Array]':
      return copyArray(x);
    case '[object Object]':
      return copyObject(x);
    // TODO is this necessary ?
    case '[object Date]':
      return new Date(x.getTime());
    default:
      return x;
  }
}
function isNaN(x) {
  return x !== x;
}
function isNumberEqual(x, y) {
  return x === y || isNaN(x) && isNaN(y);
}
function removeChartListeners(chart, x, y) {
  if (x !== y) {
    // TODO is this necessary ?
    if (x == null) {
      x = [];
    }
    // TODO is this necessary ?
    if (y == null) {
      y = [];
    }
    const xLength = x.length;
    const yLength = y.length;
    for (let i = 0; i < xLength; ++i) {
      const xValue = x[i];
      let has = false;
      // TODO make this faster ?
      for (let j = 0; j < yLength; ++j) {
        const yValue = y[j];
        // TODO is this correct ?
        if (xValue.event === yValue.event && xValue.method === yValue.method) {
          has = true;
          break;
        }
      }
      if (!has) {
        // TODO is this correct ?
        chart.removeListener(chart, xValue.event, xValue.method);
      }
    }
  }
}
function updateArray(a, x, y) {
  let didUpdate = false;
  if (x !== y) {
    const xLength = x.length;
    const yLength = y.length;
    if (xLength !== yLength) {
      a.length = yLength;
      didUpdate = true;
    }
    for (let i = 0; i < yLength; ++i) {
      if (i < xLength) {
        if (update(a, i, x[i], y[i])) {
          didUpdate = true;
        }
      } else {
        // TODO make this faster ?
        a[i] = copy(y[i]);
        // TODO is this necessary ?
        didUpdate = true;
      }
    }
  }
  return didUpdate;
}
function update(obj, key, x, y) {
  let didUpdate = false;
  if (x !== y) {
    const xType = getType(x);
    const yType = getType(y);
    if (xType === yType) {
      switch (xType) {
        case '[object Array]':
          if (updateArray(obj[key], x, y)) {
            didUpdate = true;
          }
          break;
        case '[object Object]':
          if (updateObject(obj[key], x, y)) {
            didUpdate = true;
          }
          break;
        case '[object Date]':
          if (x.getTime() !== y.getTime()) {
            // TODO make this faster ?
            obj[key] = copy(y);
            didUpdate = true;
          }
          break;
        case '[object Number]':
          if (!isNumberEqual(x, y)) {
            // TODO is the copy necessary ?
            obj[key] = copy(y);
            didUpdate = true;
          }
          break;
        default:
          if (x !== y) {
            // TODO is the copy necessary ?
            obj[key] = copy(y);
            didUpdate = true;
          }
          break;
      }
      // TODO is this correct ?
    } else {
      // TODO make this faster ?
      obj[key] = copy(y);
      didUpdate = true;
    }
  }
  return didUpdate;
}
function updateObject(chart, oldObj, newObj) {
  let didUpdate = false;
  if (oldObj !== newObj) {
    // TODO use Object.keys ?
    for (const key in newObj) {
      if (hasOwnKey(newObj, key)) {
        // TODO make this faster ?
        if (hasOwnKey(oldObj, key)) {
          // TODO should this count as an update ?
          if (key === 'listeners') {
            // TODO make this faster ?
            removeChartListeners(chart, oldObj[key], newObj[key]);
          }
          if (update(chart, key, oldObj[key], newObj[key])) {
            didUpdate = true;
          }
        } else {
          // TODO make this faster ?
          chart[key] = copy(newObj[key]);
          didUpdate = true;
        }
      }
    }
    // TODO use Object.keys ?
    for (const key in oldObj) {
      if (hasOwnKey(oldObj, key) && !hasOwnKey(newObj, key)) {
        if (key === 'listeners') {
          removeChartListeners(chart, oldObj[key], []);
        }
        delete chart[key];
        didUpdate = true;
      }
    }
  }
  return didUpdate;
}
class AmChartsDirective {
  constructor(el, AmCharts, zone) {
    this.el = el;
    this.AmCharts = AmCharts;
    this.zone = zone;
    this.delay = 0;
  }
  ngAfterViewInit() {
    // AmCharts mutates the config object, so we have to make a deep copy to prevent that
    const props = copy(this.options);
    const el = this.el.nativeElement;
    el.id = this.id;
    el.style.display = 'block';
    this.chart = this.AmCharts.makeChart(this.id, props, this.delay);
  }
  // TODO is this correct ?
  ngOnChanges(x) {
    const el = this.el.nativeElement;
    if (x.id) {
      el.id = x.id.currentValue;
    }
    if (x.options) {
      // Update the chart after init
      if (this.chart) {
        // This is needed to avoid triggering ngDoCheck
        this.zone.runOutsideAngular(() => {
          const didUpdate = updateObject(this.chart, x.options.previousValue, x.options.currentValue);
          // TODO make this faster
          if (didUpdate) {
            this.chart.validateNow(true);
          }
        });
      }
    }
  }
  ngOnDestroy() {
    if (this.chart) {
      this.AmCharts.destroyChart(this.chart);
    }
  }
}
AmChartsDirective.ɵfac = function AmChartsDirective_Factory(t) {
  return new (t || AmChartsDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(AmChartsService), i0.ɵɵdirectiveInject(i0.NgZone));
};
AmChartsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AmChartsDirective,
  selectors: [["amCharts"]],
  inputs: {
    id: "id",
    options: "options",
    delay: "delay"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AmChartsDirective, [{
    type: Directive,
    args: [{
      selector: 'amCharts'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: AmChartsService
    }, {
      type: i0.NgZone
    }];
  }, {
    id: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    delay: [{
      type: Input
    }]
  });
})();
class AmChartsModule {}
AmChartsModule.ɵfac = function AmChartsModule_Factory(t) {
  return new (t || AmChartsModule)();
};
AmChartsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AmChartsModule
});
AmChartsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [AmChartsService]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AmChartsModule, [{
    type: NgModule,
    args: [{
      declarations: [AmChartsDirective],
      exports: [AmChartsDirective],
      providers: [AmChartsService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of amcharts3-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AmChartsDirective, AmChartsModule, AmChartsService };
